@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

@font-face {
  font-family: 'Trajan Pro 3';
  font-weight: light;
  src: local('TrajanPro3Light'),
       url('./fonts/TrajanPro3Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Trajan Pro 3';
  font-weight: normal;
  src: local('TrajanPro3Regular'),
       url('./fonts/TrajanPro3Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Trajan Pro 3';
  font-weight: 600;
  src: url('./fonts/TrajanPro3Semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Trajan Pro 3';
  font-weight: bold;
  src: local('TrajanPro3Bold'),
       url('./fonts/TrajanPro3Bold.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
  font-family: 'Trajan Pro 3';
}

.overlay {
  background-color: #04041A;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  opacity: 0.80;
  z-index: -1;
}

@tailwind base;
@tailwind components;
@tailwind utilities;